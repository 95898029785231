/* DOM-wide */

body {
  overflow-y: scroll;
}

* {
  scrollbar-color: #b7b7b7 #ededed;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  border: none;
  height: 9px;
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: #ededed;
}

*::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 0px;
  border: 0px;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

/* timeline */

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  background: #d4d9df;
  content: ' ';
  display: inline-block;
  height: 100%;
  left: 30px;
  position: absolute;
  width: 3px;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  background: white;
  border-radius: 50%;
  border: 3px solid #2d6cd7;
  content: ' ';
  display: inline-block;
  height: 20px;
  left: 21px;
  position: absolute;
  width: 20px;
  z-index: 400;
}

.timeline-title {
  color: #2d6cd7;
}

/* nanobar */

.nanobar {
  height: 4px;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.nanobar .bar {
  background: #007bff;
  height: 100%;
  left: 0;
  position: absolute;
  transition: height .3s;
  width: 0;
}